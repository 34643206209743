import { Injectable } from '@angular/core';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics, setAnalyticsCollectionEnabled, logEvent } from 'firebase/analytics';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private analytics: Analytics | null = null;
  constructor() {
    const firebaseApp: FirebaseApp = initializeApp(environment.firebase);
    this.analytics = getAnalytics(firebaseApp);
    setAnalyticsCollectionEnabled(this.analytics, environment.production);
  }
  getAnalyticsInstance(): Analytics | null {
    return this.analytics;
  }
  logPageView() {
    if (this.analytics) {
      logEvent(this.analytics, 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }
  }
}