import { DeviceDetectorResult } from "device-detector-js";
import { AppActionTypes, AppActions } from "./app.actions";


export const appFeatureKey = 'app';

export interface AppState {
    user: any;
    meta: any;
    userToken: any;
    deviceId:any;
    deviceToken:any;
    isLoading: boolean;
    deviceDetails: DeviceDetectorResult;
    appConfig: any;
    eventConfig: any;
    deliveryLocation: any;
    cart: any;
}

const logoutState: AppState = {
    userToken: null,
    user: {},
    meta: {},
    isLoading: false,
    deviceDetails: {
        client: {
            type: '',
            name: '',
            version: '',
            engine: '',
            engineVersion: ''
        },
        os: {
            name: '',
            version: '',
            platform: ''
        },
        device: {
            type: '',
            brand: '',
            model: ''
        },
        bot: null
    },
    deviceId:'',
    deviceToken:'',
    appConfig: {},
    eventConfig: {},
    deliveryLocation: {},
    cart: {}
}

export const appInitialState : AppState = {
    userToken: null,
    user: {},
    meta: {},
    isLoading: false,
    deviceDetails: {
        client: {
            type: '',
            name: '',
            version: '',
            engine: '',
            engineVersion: ''
        },
        os: {
            name: '',
            version: '',
            platform: ''
        },
        device: {
            type: '',
            brand: '',
            model: ''
        },
        bot: null
    },
    deviceId:'',
    deviceToken:'',
    appConfig: {},
    eventConfig: {},
    deliveryLocation: {},
    cart: {}
}

export function appReducer(
    state: AppState = appInitialState,
    action: AppActions
): AppState {
    switch (action.type){
        case AppActionTypes.UpdateUser: {
            return {
                ...state,
                user: action.user || null
            }
        }

        case AppActionTypes.UpdateUserToken: {
            return {
                ...state,
                userToken: action.userToken || null
            }
        }

        case AppActionTypes.AddItemToCart: {    
            let revisedCart = action.item;
      
            return {
              ...state,
              cart: revisedCart,
            };
        }

        case AppActionTypes.IncreaseItemQuantity: {   
            const itemIndex = state.cart.findIndex((item: any) => item.variant?.variantId === action.item.variant?.variantId);
            
            let revisedCart = [...state.cart];
            if (itemIndex !== -1) {
                revisedCart[itemIndex] = {
                    ...revisedCart[itemIndex],
                    quantity: revisedCart[itemIndex].quantity + 1
                };
            } 

            return {
                ...state,
                cart: revisedCart,
            };
        }

        case AppActionTypes.DecreaseItemQuantity: {    
            const itemIndex = state.cart.findIndex((item: any) => item.variant?.variantId === action.item.variant?.variantId);
            
            let revisedCart = [...state.cart];
            if (itemIndex !== -1) {
                revisedCart[itemIndex] = {
                    ...revisedCart[itemIndex],
                    quantity: revisedCart[itemIndex].quantity - 1
                };
            } 

            return {
                ...state,
                cart: revisedCart,
            };
        } 
      
        case AppActionTypes.RemoveItemFromCart: {
            const itemIndex = state.cart?.orderItems?.findIndex((item: any) => item.variant?.variantId === action.item.variant?.variantId);

            let revisedCart = [...state.cart?.orderItems];
            if (itemIndex !== -1) {
                revisedCart.splice(itemIndex, 1);
            }

            let finalCart = {
                ...state.cart,
                orderItems: revisedCart
            }

            return {
                ...state,
                cart: finalCart,
            };
        }

        case AppActionTypes.ClearCart: {
            return {
              ...state,
              cart: [],
            };
        }

        case AppActionTypes.UpdateMeta: {
            return {
                ...state,
                meta: action.meta || null
            }
        }

        case AppActionTypes.UpdateLoaderVisibility: {
            return {
                ...state,
                isLoading: action.visibility
            }
        }

        case AppActionTypes.UpdateDeviceDetails: {
            return {
                ...state,
                deviceDetails: action.deviceDetails
            }
        }

        case AppActionTypes.UpdateDeviceId: {
            return {
                ...state,
                deviceId: action.deviceId
            }
        }
       
        case AppActionTypes.AddDeviceToken: {
            return {
                ...state,
                deviceToken: action.deviceToken
            }
        }

        case AppActionTypes.UpdateDeliveryLocation: {
            return {
                ...state,
                deliveryLocation: action.deliveryLocation
            }
        }

        case AppActionTypes.UpdateAppConfig: {
            return {
                ...state,
                appConfig: action.appConfig
            }
        }

        case AppActionTypes.UpdateEventConfig: {
            return {
                ...state,
                eventConfig: action.eventConfig
            }
        }

        case AppActionTypes.Logout: {
            const { deviceDetails,deviceId,deviceToken, ...logout } = 
            logoutState;
            return {
                ...state,
                ...logout,
            };
        }

        default:
            return state;
    }
}