import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'modal-design',
  templateUrl: './modal-design.component.html',
  styleUrls: ['./modal-design.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalDesignComponent implements OnInit {
  @Input() id!: string;
  @Input()
  disableBackdropClose: boolean = false;
  private element: any;
  @Input() customClasses: string = '';

  @Output() modalClosed = new EventEmitter();


  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);

    this.element.addEventListener('click', (el: any) => {
      if (el.target.className === 'fak-modal') {
        this.disableBackdropClose ? '' : this.close();
      }
    });

    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('fak-modal-open');
  }

  close(): void {
    this.modalClosed.emit(this.element.id);
    this.element.style.display = 'none';
    document.body.classList.remove('fak-modal-open');
  }
}