import { EventEmitter, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor() {}
  private modals: any[] = [];
  modalClosedAll = new EventEmitter();

  add(modal: any) {
    this.modals?.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals?.filter(x => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals?.find(x => x.id === id);
    modal?.open();
  }

  close(id: string) {
    const modal = this.modals?.find(x => x.id === id);
    modal?.close();
  }

  closeAll() {
    this.modals?.forEach((modal: any) => {
      modal?.close();
    });
  }
}
