import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { shareReplay } from 'rxjs';
import { AppState } from './app.reducer';
import { appQuery } from './app.selector';
import * as fromActions from './app.actions';

@Injectable()
export class AppStoreFacade {
  user$ = this._store.select(appQuery.getUser).pipe(shareReplay(1));
  userToken$ = this._store.select(appQuery.getUserToken).pipe(shareReplay(1));
  meta$ = this._store.select(appQuery.getMeta).pipe(shareReplay(1));
  deviceDetails$ = this._store
    .select(appQuery.getDeviceDetails)
    .pipe(shareReplay(1));
  deviceId$ = this._store.select(appQuery.getDeviceId).pipe(shareReplay(1));
  deviceToken$ = this._store.select(appQuery.getDeviceToken).pipe(shareReplay(1));
  isLoading$ = this._store.select(appQuery.getLoading).pipe(shareReplay(1));
  appConfig$ = this._store.select(appQuery.getAppConfig).pipe(shareReplay(1));
  eventConfig$ = this._store
    .select(appQuery.getEventConfig)
    .pipe(shareReplay(1));
  deliveryLocation$ = this._store
    .select(appQuery.getDeliveryLocation)
    .pipe(shareReplay(1));
  cart$ = this._store.select(appQuery.getCart).pipe(shareReplay(1));

  constructor(private _store: Store<AppState>) {}

  updateUser(user: any): void {
    this._store.dispatch(new fromActions.UpdateUser(user));
  }

  updateUserToken(userToken: any): void {
    this._store.dispatch(new fromActions.UpdateUserToken(userToken));
  }

  updateMeta(meta: any): void {
    this._store.dispatch(new fromActions.UpdateMeta(meta));
  }

  updateLoading(visibility: any): void {
    this._store.dispatch(new fromActions.UpdateLoaderVisibility(visibility));
  }

  updateDeviceDetails(deviceDetails: any): void {
    this._store.dispatch(new fromActions.UpdateDeviceDetails(deviceDetails));
  }

  updateDeviceId(deviceId: any): void {
    this._store.dispatch(new fromActions.UpdateDeviceId(deviceId));
  }
  addDeviceToken(deviceToken:any): void {
    this._store.dispatch(new fromActions.AddDeviceToken(deviceToken));
  }
  updateAppConfig(appConfig: any): void {
    this._store.dispatch(new fromActions.UpdateAppConfig(appConfig));
  }

  updateEventConfig(eventConfig: any): void {
    this._store.dispatch(new fromActions.UpdateEventConfig(eventConfig));
  }

  updateDeliveryLocation(deliveryLocation: any): void {
    this._store.dispatch(
      new fromActions.UpdateDeliveryLocation(deliveryLocation)
    );
  }

  addItemToCart(item: any): void {
    this._store.dispatch(new fromActions.AddItemToCart(item));
  }

  removeItemFromCart(item: any): void {
    this._store.dispatch(new fromActions.RemoveItemFromCart(item));
  }

  clearCart(): void {
    this._store.dispatch(new fromActions.ClearCart());
  }

  logout(): void {
    this._store.dispatch(new fromActions.Logout());
  }

  increaseItemQuantity(item: any, quantity: any): void {
    this._store.dispatch(new fromActions.IncreaseItemQuantity(item, quantity));
  }

  decreaseItemQuantity(item: any, quantity: any): void {
    this._store.dispatch(new fromActions.DecreaseItemQuantity(item, quantity));
  }
}
