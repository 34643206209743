import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ContentType } from '@core/enum/content-type.enum';
import { environment } from '@env/environment.prod';
import { environment as env } from '@env/environment';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PER_PAGE,
} from '@core/constants/pagination.constants';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  user: any;
  openLocation: boolean = false;
  feedSlug: string = '';
  tagId: string = '';
  CONTENT_TYPE = ContentType;
  userToken: any;
  private apiUrl = {
    oums: env.OUMS_API_APP_URL,
    oumsUrl: env.OUMS_API_URL,
    pms: env.PMS_UPLOADIMAGE_URL,
  };

  endpoints = {
    wishlist: '/wishlist',
    coupon: '/coupon',
  };
  currentLocationName: Subject<any> = new Subject<any>();
  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();
  deliveryLocation: any;

  constructor(
    private _appStoreFacade: AppStoreFacade,
    private _http: HttpClient
  ) {
    this._appStoreFacade.user$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((user: any) => {
        this.user = user;
      });
    this._appStoreFacade.deliveryLocation$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((deliveryLocation: any) => {
        this.deliveryLocation = deliveryLocation;
      });
    this._appStoreFacade.userToken$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((userToken: any) => {
        this.userToken = userToken;
      });
  }

  get imageBaseUrl() {
    return environment.IMAGE_DOMAIN;
  }

  get isUserLoggedIn() {
    return this.user?.userId;
  }

  getAuthToken() {
    return this.user?.token || this.userToken;
  }
  applyFilters(data: any, sortBy: any, pagination: any) {
    let page =
      pagination && pagination.currentPage
        ? pagination.currentPage
        : DEFAULT_PAGE_INDEX;
    let limit =
      pagination && pagination.perPage ? pagination.perPage : DEFAULT_PER_PAGE;
    const apiUrl = `${this.apiUrl.pms}/product/filter-product?sortBy=${sortBy}&lat=${this.deliveryLocation.latitude}&long=${this.deliveryLocation.longitude}&page=${page}&limit=${limit}`;
    return this._http.post(`${apiUrl}`, data);
  }
  addRecentProducts(data: any) {
    const apiUrl = `${this.apiUrl.oums}/user/add-recent-product`;
    return this._http.post(apiUrl, data, {
      headers: this.getAuthToken(),
    });
  }
  getRecentProducts() {
    const apiUrl = `${this.apiUrl.pms}/product/recent-viewed-product`;
    return this._http.get(apiUrl, {
      headers: this.getAuthToken(),
    });
  }
  similarProducts(categoryId:any) {
    const apiUrl = `${this.apiUrl.pms}/product/similar-product/${categoryId}`;
    return this._http.get(apiUrl, {
      headers: this.getAuthToken(),
    });
  }
  moreFromBrand(brandId:any) {
    const apiUrl = `${this.apiUrl.pms}/product/similar-brand-product/${brandId}`;
    return this._http.get(apiUrl, {
      headers: this.getAuthToken(),
    });
  }
  getCurrentPosition(): Promise<{ lat: number; lon: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position?.coords?.latitude,
              lon: position?.coords?.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  getPlaceName(lat: any, lng: any) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${environment.GOOGLE_MAP_KEY}`;
    return this._http.get<any>(url).pipe(
      map((response) => {
        if (response?.results?.length > 0) {
          return response.results;
        } else {
          throw new Error('No address found');
        }
      })
    );
  }
  pageName(contentType: any) {
    let page_name = '';
    switch (contentType) {
      case this.CONTENT_TYPE.PRODUCT:
        page_name = 'product_detail';
        break;
      case this.CONTENT_TYPE.VENDOR:
        page_name = 'vendor_detail';
        break;
      default:
        break;
    }
    return page_name;
  }

  copyToClipboard(text: any) {
    const textToCopy = text;

    if (textToCopy) {
      navigator?.clipboard
        ?.writeText(textToCopy)
        .then(() => {})
        .catch((err) => {});
    }
  }
  searchProduct(
    query: string,
    pagination: any,
    options: { limit?: number } = {}
  ): Observable<any> {
    let page =
      pagination && pagination.currentPage
        ? pagination.currentPage
        : DEFAULT_PAGE_INDEX;
    let limit =
      pagination && pagination.perPage ? pagination.perPage : DEFAULT_PER_PAGE;
    const apiUrl = `${this.apiUrl.pms}/product/search-product`;
    let params = `q=${query}`;
    if (options.limit) {
      params += `&limit=${options.limit}`;
    }
    return this._http.get(`${apiUrl}?q=${query}&page=${page}&limit=${limit}`);
  }
  getSearchHistory() {
    const apiUrl = `${this.apiUrl.oumsUrl}/search/get-serch-history`;
    return this._http.get(apiUrl, {
      headers: this.getAuthToken(),
    });
  }
  filterSearchedProducts(data:any){
    const apiUrl = `${this.apiUrl.pms}/product/search-filtered-product`;
    // Dynamically build the query string
    const queryParams = Object.keys(data)
      .filter((key) => data[key] !== undefined && data[key] !== null && data[key] !== '') 
      // Filter out empty keys
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`) 
      // Encode key-value pairs
      .join('&'); // Join them into a query string
  
    const finalUrl = queryParams ? `${apiUrl}?${queryParams}` : apiUrl;
    return this._http.get(finalUrl, {
      headers: this.getAuthToken(),
    });
  }
  addSearchHistory(searchQuery: any) {
    const apiUrl = `${this.apiUrl.oumsUrl}/search/add-serch-history?searchQuery=${searchQuery}`;
    return this._http.get(apiUrl, {
      headers: this.getAuthToken(),
    });
  }
  getWishlist() {
    return this._http.get(`${this.apiUrl.oums}${this.endpoints.wishlist}`, {
      headers: this.getAuthToken(),
    });
  }
  postWishlist(data: any) {
    return this._http.post(
      `${this.apiUrl.oums}${this.endpoints.wishlist}`,
      data,
      {
        headers: this.getAuthToken(),
      }
    );
  }
  deleteWishlist(id: any) {
    return this._http.delete(
      `${this.apiUrl.oums}${this.endpoints.wishlist}/${id}`,
      {
        headers: this.getAuthToken(),
      }
    );
  }
  // applyCoupon(data: any) {
  //   return this._http.post(
  //     `${this.apiUrl.oumsUrl}${this.endpoints.coupon}`,
  //     data,
  //     {
  //       headers: this.getAuthToken(),
  //     }
  //   );
  // }
  getCoupon() {
    return this._http.get(`${this.apiUrl.oumsUrl}${this.endpoints.coupon}`, {
      headers: this.getAuthToken(),
    });
  }
}
