import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  endpoints = {
    updateProfile: '/auth/update-profile',
    updatePassword:  '/auth/update-password',
    getUsrProfile: '/user/',
    editUploadImage:'/upload'
  }

  private oumsApiUrl = environment.OUMS_API_APP_URL;
  private pmsApiImageUrl = environment.PMS_UPLOADIMAGE_URL;

  constructor(
    private _http: HttpClient,
    private _appStoreFacade: AppStoreFacade,
    private _route: Router,
    private _commonService: CommonService
  ) { 
    
  }

  sendOTP(data: any) {
    return this._http.post(`${this.oumsApiUrl}/auth/signin-check`, data).pipe(
      map(response => {
        if (response) {
          return response;
        } else {
          throw new Error('No address found');
        }
      })
    );
  }

  verifyOtp(data: any){
    return this._http.post(`${this.oumsApiUrl}/auth/verify-otp`, data).pipe(
      map(response => {
        if (response) {
          return response;
        } else {
          throw new Error('Not valid OTP');
        }
      })
    );
  }

  updateProfile(data: any, id: any): Observable<any[]> {
    return this._http.put<any>(`${this.oumsApiUrl}${this.endpoints.updateProfile}?id=${id}`,data , { 
      headers: this.getHeaders()
     });
  }

  updatePassword(data: any, id: any): Observable<any[]> {
    return this._http.put<any>(`${this.oumsApiUrl}${this.endpoints.updatePassword}?id=${id}`,data , { 
      headers: this.getHeaders()
     });
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this._commonService.getAuthToken) {
      headers = headers.set('Authorization', `Bearer ${this._commonService.getAuthToken}`);
    }
    return headers;
  }

  logout(){
    this._appStoreFacade.logout();
    this._route.navigate(['']);
  }

  getUserEditData(id: any): Observable<any[]> {
    return this._http.get<any>(`${this.oumsApiUrl}${this.endpoints.getUsrProfile}/${id}`, { 
      headers: this.getHeaders()
     });
    }
    uploadImage(data: any) {
      return this._http.post(
        `${this.pmsApiImageUrl}${this.endpoints.editUploadImage}`,
        data,
        {}
      );
    }
    
}
