import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private _commonService: CommonService
    ) {}
 
    canActivate(): boolean {
      let response: any;
      if (this._commonService.isUserLoggedIn) {
        response = true;
      } else {
        response = false;
      }
      return response;
    }
}