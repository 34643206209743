import { Injectable } from '@angular/core';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private _appStoreFacade: AppStoreFacade) { }

  generateDeviceId(): string {
    let deviceId: string | null = null;
    this._appStoreFacade.deviceId$.subscribe((id) => {
      deviceId = id;
    });
    if (!deviceId) {
      deviceId = uuidv4();
      this._appStoreFacade.updateDeviceId(deviceId); 
    }
    return deviceId;
  }
}
