import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState, appFeatureKey } from "./app.reducer";
import { get } from "lodash-es";

const getAppState = createFeatureSelector<AppState>(appFeatureKey);

export const getUser = createSelector(getAppState, (state:any) => 
    get(state, 'user')
);

export const getUserToken = createSelector(getAppState, (state:any) => 
    get(state, 'userToken')
);

export const getMeta = createSelector(getAppState, (state:any) => 
    get(state, 'meta')
);

export const getLoading = createSelector(getAppState, (state:any) => 
    get(state, 'isLoading')
);

export const getDeviceDetails = createSelector(getAppState, (state:any) => 
    get(state, 'deviceDetails')
);

export const getDeviceId = createSelector(getAppState, (state:any) => 
    get(state, 'deviceId')
);
export const getDeviceToken = createSelector(getAppState, (state:any) => 
    get(state, 'deviceToken')
);
export const getAppConfig = createSelector(getAppState, (state:any) => 
    get(state, 'appConfig')
);

export const getEventConfig = createSelector(getAppState, (state:any) => 
    get(state, 'eventConfig')
);

export const getDeliveryLocation = createSelector(getAppState, (state:any) => 
    get(state, 'deliveryLocation')
);

export const getCart = createSelector(getAppState, (state:any) => 
    get(state, 'cart')
);

export const appQuery = {
    getUser,
    getUserToken,
    getMeta,
    getLoading,
    getDeviceDetails,
    getDeviceId,
    getDeviceToken,
    getAppConfig,
    getEventConfig,
    getDeliveryLocation,
    getCart
}