import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { CommonService } from '../common/common.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router, 
    private _commonService: CommonService,
    private _authService: AuthService
) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = request;
    const authToken = this._commonService.getAuthToken();
    
    if (authToken && !authReq.url.includes('https://maps.googleapis.com/maps/api')) {
      authReq = request.clone({
        setHeaders: { Authorization: `Bearer ${authToken}` }
      });
    }

    return next.handle(authReq).pipe(
      catchError(err => {
        if ((err.status === 401 && (err.error?.message?.includes('Unauthorized') || err.statusText === 'Unauthorized')) ||
            (err.status === 403 && err.error?.message?.includes('Invalid token or Permission denied.'))) {
          this._authService.logout();
          this._router.navigate(['/']);
          return throwError(() => new Error('Unauthorized or Permission denied')); // Re-throw for clarity
        }
      
        return throwError(err);
      })
    );
  }
}