import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '@components/common/modal-design/modal.service';
import { CTA_SOURCES } from '@core/enum/cta-sources.enum';
import { catchError, delay, Subject, takeUntil, throwError } from 'rxjs';
import { AppStoreFacade } from 'src/app/+state/app-store.facade';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-signin-check',
  templateUrl: './signin-check.component.html',
  styleUrls: ['./signin-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninCheckComponent implements OnInit, OnDestroy {
  countdown: number = 30;
  deviceId: any;
  showInvalidOtpMessage: boolean = false;
  isOtpGenerated: boolean = false;
  resendCountdown: number = 30;
  resendDisabled: boolean = true;
  verifyDisabled: boolean = true;
  interval: any;
  deviceDetails: any;
  enteredOtp: string = '';
  correctOtp: string = '';
  showErrorMessage: boolean = false;
  inputError: boolean[] = [false, false, false, false];
  showVerifyButton: boolean = true;
  router: any;
  isButtonDisabled: boolean = true;
  isLoginSuccess = false;
  loginForm: any;
  loginStep = 1;
  otpConfig = {
    allowNumbersOnly: true,
    length: 6,
    disableAutoFocus: false,
    inputStyles: {
      width: '2.6rem',
      height: '2.6rem',
      'margin-left': '0.25rem',
      'margin-right': '0.25rem',
      'border-radius': '0.25rem',
    },
  };
  userName: string = '';
  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();
  phoneNo: string = '';
  deviceToken: any;
  errorData: any;

  constructor(
    private _authService: AuthService,
    private _commonService: CommonService,
    public _modalService: ModalService,
    private _cdr: ChangeDetectorRef,
    private _appStoreFacade: AppStoreFacade,
    private fb: FormBuilder,
    private ngZone: NgZone
  ) {
    this._appStoreFacade.deviceDetails$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((device: any) => {
        this.deviceDetails = device;
        this._cdr.markForCheck();
      });
    this._appStoreFacade.deviceId$
      .pipe(takeUntil(this._unsubscribe$), delay(0))
      .subscribe((id: string) => {
        this.deviceId = id;
        this._cdr.markForCheck();
      });
    this._appStoreFacade.deviceToken$
      .pipe(takeUntil(this._unsubscribe$), delay(0))
      .subscribe((id: string) => {
        this.deviceToken = id;
        this._cdr.markForCheck();
      });
    this.loginForm = this.fb.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    });
  }

  ngOnInit(): void {}
  handleEnter(): void {
    if (this.loginStep === 1) {
      this.sendOTP();
    } else if (this.loginStep === 2) {
      this.verifyOtp();
    }
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }

  sendOTP() {
    this.isButtonDisabled = true;
    let payload = {
      os: this.deviceDetails?.os?.name,
      mobileNumber: `${this.loginForm.get('mobileNumber')!.value}`,
      deviceId: this.deviceId,
      osVersion: this.deviceDetails?.os?.version,
      modelNumber: this.deviceDetails?.device?.model,
      domainName: 'fashquik.com',
      userAgent: 'web,mobile,tab',
      signinSource: 'HEADER_MENU',
      appVersion: '1.0.0',
    };
    this.deviceId = payload.deviceId;
  
    this._authService.sendOTP(payload)
      .pipe(
        catchError((error) => {
          this.errorData = { message: error.error?.message || 'An unexpected error occurred. Please try again.' };
          this._cdr.markForCheck();
          return throwError(error);
        })
      )
      .subscribe((data: any) => {
        if (data && data.statusCode === 200) {
          this.startTimer();
          this.isOtpGenerated = true;
          this.correctOtp = data?.otp;
          this.phoneNo = data?.mobileNumber;
          this.loginStep = 2;
          this.errorData = null; 
        } else {
          this.errorData = { message: data?.message || 'An error occurred. Please try again later.' };
        }
        this._cdr.markForCheck();
      });
  }
  gotoLogin() {
    this.loginStep = 1;
  }

  startTimer(): void {
    this.countdown = 30;
    this.isButtonDisabled = true;
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.ngZone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.ngZone.run(() => this.countdown--);
        } else {
          clearInterval(this.interval);
          this.isButtonDisabled = false;
        }
        this._cdr.detectChanges();
      }, 1000);
    });
  }

  resendOTP(): void {
    this.resendDisabled = true;
    this.resendCountdown = 30;
    this.startTimer();
  }

  backToLogin() {
    this.isOtpGenerated = false;
  }
  closeModal(): void {
    this._modalService.close('loginPopup');
    this.loginStep = 1;
    this._commonService.openLocation = true;
    this._cdr.markForCheck();
  }
  verifyOtp(): void {
    let payload = {
      mobileNumber: this.phoneNo,
      deviceId: this.deviceId,
      otp: this.enteredOtp,
      deviceToken: this.deviceToken,
      signinSource: CTA_SOURCES.HEADER_MENU,
    };

    this._authService.verifyOtp(payload).subscribe(
      (data: any) => {
        if (data) {
          this._appStoreFacade.updateUserToken(data?.data?.token);
          this._appStoreFacade.updateUser(data?.data);
          this.userName = data?.data?.profile.name;
          this.loginStep = 3;
          this.showInvalidOtpMessage = false;
        } else {
          this.showErrorMessage = true;
          this.showInvalidOtpMessage = true;
        }
        this._cdr.markForCheck();
      },
      (error) => {
        this.showErrorMessage = true;
        this.showInvalidOtpMessage = true;
        this._cdr.markForCheck();
      }
    );
  }

  onOtpChange(otp: any): void {
    this.enteredOtp = otp;
  }

  updateEnteredOtp(): void {
    this.enteredOtp = '';
    for (let i = 0; i < 6; i++) {
      this.enteredOtp += (
        document.getElementById(`otp${i}`) as HTMLInputElement
      ).value;
    }
  }

  displayTitle() {
    const loginInstructions = {
      title: '',
      description: '',
    };
    if (this.loginStep === 1) {
      loginInstructions.title = 'Login / Sign Up';
    } else if (this.loginStep === 2) {
      loginInstructions.title = 'OTP Verification';
      loginInstructions.description =
        'We have sent the verification code to your mobile number';
    }

    return loginInstructions;
  }
}
