export const environment = {
    production: true,
    APP_URL: 'https://fashquik.com',
    IMAGE_DOMAIN: 'http://nextcloud.fashquik.com',
    WMS_API_URL: 'https://wms-api.fashquik.com/api/core',
    WMS_API_APP_URL: 'https://wms-api.fashquik.com/api/app',
    OUMS_API_APP_URL: 'https://oums-api.fashquik.com/api/app',
    OUMS_API_URL: 'https://oums-api.fashquik.com/api',
    PMS_API_APP_URL: 'https://pms-api.fashquik.com/api/app',
    GOOGLE_MAP_KEY: 'AIzaSyAto-YQGOiLk7pD0T8GTeTJJHBGaOrQHI8',
    razorPe_key:'rzp_live_qGFWdcj4XjwZql' ,  
    PMS_UPLOADIMAGE_URL:'https://pms-api.fashquik.com/api',
    firebase: {
        apiKey: 'AIzaSyCVfZvmT_m_814MXHrkJMDUXpYS8WFg114',
        authDomain: 'fashquik-fakapp.firebaseapp.com',
        projectId: 'fashquik-fakapp',
        storageBucket: 'fashquik-fakapp.appspot.com',
        messagingSenderId: '246594818888',
        appId: '1:246594818888:web:cebc23e81fc7a45526197d',
        measurementId: 'G-4WSCE8FMRQ',
        vapidKey:'BP1aPmrsjZ5h6E9Imo4CpuvLHHhZpevHPOcH79num0gOKdbU9utyYPR_6jP2CLrAwolSxTKbi9zBJiGGzRHX1Qk'
      }
}
